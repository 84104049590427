<template>
  <div>
    <TopMenuSubheadline :title="title"/>
    <div class="app-content-box bg-gray">
      <div class="wrapper pt-4 pb-3">
        <div class="container">
            <div class="text-center top-heading-small mt-4">
              nazwa planu czytelniczego
            </div>
            <div class="heading-summary text-center mt-3">
              <span>
               {{planSummary.name}}
              </span>
            </div>
        </div>
      <div class="container">
        <div class="status-icon-row-summary row mt-4">
          <div class="col-4 pr-0">
            <div class="d-inline-block text-left">
             <div class="icon-big mb-0">
                <i class="far fa-calendar-alt"></i>
             </div>
             <br> sesje <br> <span v-if="planSummary.session === 'day'">codzienne</span>
             <span v-if="planSummary.session === 'week'">tygodniowe</span>
            </div>
          </div>
          <div class="col-4 pl-0 pr-0 text-center">
            <div class="d-inline-block text-left">
              <div class="icon-big mb-0">
                <i class="fas fa-book"></i>
              </div>
              <br>
              <span v-if="planSummary.type === 'books'">założenie <br> {{planSummary.books_total}} książek</span>
              <span v-if="planSummary.type === 'pages'">założenie <br> {{planSummary.pages_per}} stron</span>
              <span v-if="planSummary.type === 'time'">założenie <br> {{planSummary.time_per}} minut</span>
            </div>
          </div>
          <div class="col-4 pl-0 text-right">
            <div class="d-inline-block text-left">
            <div class="icon-big mb-0">
              <i class="far fa-clock"></i>
            </div>
              <br>
              czas trwania <br>
              <span>
                {{planSummary.full_time}}
                <span v-if="planSummary.full_time_unit === 'month'">miesięcy</span>
                <span v-if="planSummary.full_time_unit === 'week'">tygodni</span>
              </span>
            </div>
          </div>
        </div>
         <hr class="section-spacer section-spacer-black">
        <div class="text-center top-heading-medium mt-4 mb-3">
          średnia dzienna liczba stron do przeczytania
        </div>
        <div class="heading-summary text-center">
          {{planSummary.pages_daily}}
        </div>
        <hr class="section-spacer section-spacer-black">
        <div class="text-center top-heading-medium mt-4 mb-3">
          średni dzienny czas czytania
        </div>
        <div class="heading-summary text-center">
         {{planSummary.minuts_daily}} minut
        </div>
         <hr class="section-spacer section-spacer-black">
        <div class="text-center top-heading-medium mt-4 mb-3">
          liczba książek w planie
        </div>
        <div class="heading-summary text-center">
          <span>
            {{planSummary.books_total}}
          </span>
        </div>
        <router-link :to="'/wybierz-ksiazki/' + planId">
          <button class="btn-black-full w-100 mt-3 mb-1">Zmień</button>
        </router-link>
          <button class="btn-black-full w-100 mt-1 mb-5" @click="savePlan">Zapisz</button>
          <ModalSlot title="Dodaj książkę" v-if="modalMoreBooks" @close-modal="close(false)">
            <div>
              <div class="text-center mb-4 modal-main-title">Do planu została dodana zbyt mała liczba książek, aby móc go zrealizować. <br> Dodaj kolejne książki.</div>
              <router-link :to="'/wybierz-ksiazki/' + planId"><button class="btn-black-border w-100 mt-0 mb-1">Edytuj książki w planie</button></router-link>
              <button class="btn-black-full w-100 mt-1 mb-1" @click="close(false)">Zamknij</button>
            </div>
          </ModalSlot>
      </div>
      </div>
    </div>
  </div>
</template>
<script>
import TopMenuSubheadline from '../components/TopMenuSubheadline'
import ModalSlot from '../components/ModalSlot'
export default {
  name: 'TrainingPlanSummary',
  components: {
    TopMenuSubheadline,
    ModalSlot
  },
  data () {
    return {
      planSummary: [],
      title: '',
      planId: 0,
      modalMoreBooks: false
    }
  },
  async created () {
    await this.$https('/plans', {
      method: 'GET',
      mode: 'no-cors',
      crossDomain: true,
      headers: {
      }
    })
      .then(({ data }) => {
        const planAmout = data.response.length - 1
        this.planSummary = data.response[planAmout]
        this.planId = this.planSummary.id
        if (this.planSummary.type === 'books') {
          this.title = 'Ustaw swój plan książkowy'
        } else if (this.planSummary.type === 'time') {
          this.title = 'Ustaw swój plan czasu'
        } else if (this.planSummary.type === 'pages') {
          this.title = 'Ustaw swój plan stronicowy'
        } else {
          this.title = 'Ustaw swój plan'
        }
      })
      .catch(error => {
        console.log('', error)
      })
  },
  methods: {
    close () {
      this.modalMoreBooks = false
    },
    savePlan () {
      const getData = ''
      this.$https('plan/' + this.planId + '/start', {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: getData
      }).then(response => {
        // this.$router.push('/plan-treningowy')
        if (response.data.response === 0) {
          this.modalMoreBooks = true
        } else {
          this.$router.push('/plan-treningowy')
        }
      })
        .catch(error => {
          console.log('', error)
        })
    }
  }
}
</script>
<style lang="scss" scoped>
  .icon-big {
    font-size: 25px;
  }
  .heading-summary {
    display: block;
    font-size: 30px;
    line-height: 28px;
    font-weight: lighter;
  }
</style>
